<template>
  <div>
    <h4>
      <slot></slot>
    </h4>

    <div style="display: flex; gap: 40%">

      <div>
        <label class="form-control company-type">
          <input type="radio" v-model="companyName" name="companyName" value=0 />Alla
        </label>
        <label class="form-control company-type" v-for="item in listOfRecruitmentTypes[0]" :key="item.id">
          <input type="radio" v-model="companyName" name="companyName" :value=item.id />
          {{ item.name }}
        </label>
      </div>

      <div>
        <label class="form-control company-type" v-for="item in listOfRecruitmentTypes[1]" :key="item.id">
          <input type="radio" v-model="companyName" name="companyName" :value=item.id />
          {{ item.name }}
        </label>
      </div>

    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
// import { event } from "vue-gtag";

export default {
  name: "SelectCompanyType",
  mounted() {
    var store = new useStore();
    store.dispatch("guide/GET_RECURITMENT_TYPES", "", { root: true });
  },
  setup() {
    const store = new useStore();

    const companyName = computed({
      get() {
        let types = store.state.guide.filter_company_type;
        return types
      },
      set(value) {
        //triggerEventToGA(value);

        store.dispatch("guide/UPDATE_FILTER_COMPANY_NAME", value, {
          root: true,
        });
      },
    });

    const listOfRecruitmentTypes = computed({
      get() {
        return [
          store.state.guide.recruitment_types.slice(0, 2),
          store.state.guide.recruitment_types.slice(2)
        ]
      }
    })

    // const triggerEventToGA = (value) => {
    //   let company_type = "Rekrytering";

    //   switch (value) {
    //     case 2:
    //       company_type = "Bemanning";
    //       break;
    //     case 3:
    //       company_type = "Hryköp";
    //       break;
    //   }

    //   event("search", {
    //     event_category: "Sök",
    //     event_label: "Typ av företag",
    //     value: company_type,
    //   });
    // };

    return {
      companyName,
      listOfRecruitmentTypes
    };
  },
};
</script>
