<template>
    <div class="card banner-box" style="align-items: center; justify-content: center; ">
        <div :banner-id=item.id :banner-url=item.image_url @click="redirectBanner(item.id)">
            <img :src=item.mobile_image class="img-banner" />
        </div>
    </div>
</template>

<script lang="js">
// import { ref } from 'vue'
// import { useElementVisibility } from '@vueuse/core'
import { useStore } from "vuex";

export default {
    name: "InCardBanner",
    props: {
        item: {}
    },
    mounted() {
        let store = new useStore();
        store.dispatch("banner/SET_IMPRESSION", { banner_id: this.item.id }, {
            root: true,
        })
    },
    setup() {
        const redirectBanner = function (id) {
            window.open('/banner/' + id, '_blank')
        }

        return {
            redirectBanner
        }
    }
}
</script>
<style scoped> .img-banner {
     max-height: 320px !important;
     max-width: 100%;
     align-content: center;
     padding-left: 0;
     padding-right: 0;
     margin-left: 0;
     display: flex;
     flex-wrap: initial;
     margin-right: 0;
 }
</style>