<template>
  <div>
    <h4><slot></slot> <a href="/artikel/kompetensforetagen" class="info-icon"> &quest; </a></h4>
    <label class="form-control">
      <input type="checkbox" v-model="quality" />
      Ja
    </label>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
// import { event } from "vue-gtag";

export default {
  name: "SelectQuality",
  setup() {
    const store = new useStore();

    const quality = computed({
      get() {
        return store.state.guide.filter_quality;
      },
      set(value) {
       
        // if (value) {
        //   tiggerEventToGA(value);
        // }

        store.dispatch("guide/UPDATE_FILTER_QUALITY", value, {
          root: true,
        });
      },
    });

    // const tiggerEventToGA = (value) => {
    //   event("search", {
    //     event_category: "Sök",
    //     event_label: "Kreditvärdighet",
    //     value: value,
    //   });
    // };

    return {
      quality,
    };
  },
};
</script>
