<template>
  <IndexPage></IndexPage>
</template>

<script>
import IndexPage from './views/IndexPage.vue'

export default {
  name: 'App',
  components: {
    IndexPage
  }
}
</script>