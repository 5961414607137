<template>
  <div @click="clearFilters()" class="btn"><slot></slot></div>
</template>

<script>
import { useStore } from "vuex";

export default {
  setup() {
    const store = new useStore();

    const clearFilters = () => {
      store.dispatch("guide/CLEAR_FILTERS", "", { root: true });
    };

    return {
      clearFilters,
    };
  },
};
</script>
<style scoped>
.btn {
  background-color: #65af77;
  color: white;
  
}
</style>
