<script>
export default {
  name: 'IntroText'
}
</script>

<template>
    <div class="intro-text" id="intro-text">
        <h1>Välkommen till Rekryteringsguiden</h1>
        <p>Vi på Rekryteringsguiden hjälper ditt företag att hitta rekryteringsfirman som passar just era behov - för att hitta den perfekta kandidaten. </p>
        <p style="margin-top: 10px">Vill du synas med ditt rekryterings- eller bemanningsföretag här? <a style="font-size: 16px; color: #333; border-bottom: 1px solid #333" target="_blank" href="https://hrnytt.se/kampanjer/r/1023/utbildning-link-to-ocast">Kontakta oss</a></p>
    </div>
</template>

