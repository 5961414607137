import {
    getProfessions,
    getSelectedCompanies,
    getRecruitmentTypes
}

    from '../api/api_guide'

const state = () => ({
    ajax_state: false,
    error_msg: "",
    TimeStamp: new Date(),
    professions: [],
    recruitment_types: [],
    companies: [],
    filter_professions: [],
    filter_recruitment_types: [],
    filter_company_type: 0,
    filter_quality: false,
    filter_credit: 0,
    page: 1,
    read_from_url: true,
    query_offset: 0,
    query_limit: 12,
    all_companies: 0,
    show_more_button_is_visible: true
})

const getters = {
    companies: state => {
        return state.companies
    }
}

const actions = {
    GET_PROFFESIONS: ({ commit }) => {
        getProfessions().then((data) => {

            commit('set_proffesions', data)
        }).catch((error) => {
            console.log(error)
            commit('set_upload_state', false)
        })

    },

    GET_RECURITMENT_TYPES: ({ commit }) => {
        getRecruitmentTypes().then((data) => {

            commit('set_recruitment_types', data)
        }).catch((error) => {
            console.log(error)
            commit('set_upload_state', false)
        })

    },

    CLEAR_FILTERS: ({ commit, dispatch }) => {
        commit('set_filter_professions', [])
        commit('set_filter_company_type', 0)
        commit('set_filter_quality', false)
        commit('set_filter_credit', 0)
        commit('set_read_from_url', false)
        commit('set_companies', [])
        commit('set_query_offset', 0)
        commit('set_show_more_button_is_visble', true)
        dispatch('GET_COMPANIES')
    },

    CLEAR_RESULTS_WHEN_SEARCH: ({ commit, dispatch }) => {
        commit('set_companies', [])
        commit('set_query_offset', 0)
        dispatch('GET_COMPANIES')
    },

    UPDATE_FILTER_PROFFESION: ({ commit }, value) => {
        commit('set_filter_professions', value)
        //dispatch('GET_COMPANIES')
    },

    UPDATE_FILTER_COMPANY_NAME: ({ commit }, value) => {
        commit('set_filter_company_type', value)
        //dispatch('GET_COMPANIES')
    },

    UPDATE_FILTER_CREDIT: ({ commit }, value) => {
        commit('set_filter_credit', value)
        //dispatch('GET_COMPANIES')
    },

    UPDATE_FILTER_QUALITY: ({ commit }, value) => {
        commit('set_filter_quality', value)
        //dispatch('GET_COMPANIES')
    },

    GET_NEXT_PAGE_OF_RESULTS: ({ state, commit, dispatch }) => {
        let current_offset = state.query_offset;
        let new_offset = current_offset + state.query_limit;

        commit('set_query_offset', new_offset)
        dispatch('GET_COMPANIES')

    },


    GET_COMPANIES: ({ state, commit }) => {
        commit('set_ajax', true)

        /**
         * Processing priority
         * 
         * 1. URL
         * 2. Localstorage
         */

        /** 1. Analyze params in URL */
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        const url_type_of_company = urlParams.get('company_type')
        const url_proffs = urlParams.get('proffs')

        // Read only once from url.
        if (state.read_from_url) {

            let tr = false;

            if (url_type_of_company) {
                commit('set_filter_company_type', url_type_of_company)
                tr = true;
            }

            if (url_proffs) {
                commit('set_filter_professions', url_proffs.split(","))
                tr = true;
            }

            if (tr) {
                // Don't read more params from url
                commit('set_read_from_url', false)
            }

        }
        if (state.query_offset === 11) {
            commit('set_query_offset', 12)
            commit('set_query_limit', 12)
        }

        let proffs = state.filter_professions.join(',')
        let credit = state.filter_credit
        let query_for_backend = `credit=${credit}&quality=${state.filter_quality}&company_type=${state.filter_company_type}&proffs=${proffs}&limit=${state.query_limit}&offset=${state.query_offset}`

        localStorage.setItem('rg_save_pyload', query_for_backend)

        getSelectedCompanies(query_for_backend).then((data) => {

            setTimeout(function () {

                commit('set_amount_of_companies', data.count)
                commit('set_companies', data.results)
                commit('set_ajax', false)

                if (data.count > 12) {
                    commit('set_show_more_button_is_visble', true)
                }

                if (data.next === null) {

                    commit('set_show_more_button_is_visble', false)
                }
            }, 125
            )
        }).catch((error) => {
            console.log(error)
            commit('set_upload_state', false)
            commit('set_ajax', false)
        })
    }
}

const mutations = {
    set_proffesions(state, value) {
        state.professions = value
    },
    set_listOfProfessions(state, value) {
        state.recruitment_types = value
    },
    set_filter_professions(state, value) {
        state.filter_professions = value
    },
    set_filter_company_type(state, value) {
        state.filter_company_type = parseInt(value)
    },
    set_filter_quality(state, value) {
        state.filter_quality = Boolean(value)
    },
    set_filter_credit(state, value) {
        state.filter_credit = value
    },
    set_recruitment_types(state, value) {
        state.recruitment_types = value
    },
    set_companies(state, value) {


        if (value.length === 0) {
            state.companies = value
        } else {

            for (let company of value) {
                state.companies.push(company)
            }
        }

    },
    set_read_from_url(state, value) {
        state.read_from_url = value
    },
    set_ajax(state, value) {
        state.ajax_state = value
    },
    set_query_offset(state, value) {
        state.query_offset = value
    },
    set_amount_of_companies(state, value) {
        state.all_companies = value
    },

    set_query_limit(state, value) {
        state.query_limit = value
    },
    set_show_more_button_is_visble(state, value) {
        state.show_more_button_is_visible = value
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}