<template type="js">
  <div>
    
 
    <div class="show-more-info" v-if="companies?.length > 0  ">
      <div class="show-more-companies-btn" ></div>
      
     {{ companies.length }} av {{ all_companies }}

    <div
      @click="showMoreCompanies"
      class="show-more-companies-btn"
      v-if="companies?.length > 0 && showMoreButtonState == true"
    > <span style="font-size: 14px; padding: 0 8px">|</span> visa fler &gt;&gt; </div>

  </div>

    <div
      v-if="companies?.length > 6"
      class="show-more-go-to-top"
      @click="goToTop">
      &#8593;
    </div>
    
    <div v-if="companies?.length == 0 && isAjaxActive == false" class="card empty-card">Inga resultat.</div>
  </div>
</template>

<script type="js">
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "showMoreButton",
  setup() {
    const store = new useStore();

    let showMoreCompanies = () => {
      store?.dispatch("guide/GET_NEXT_PAGE_OF_RESULTS", "", {
        root: true,
      });
    };

    let showMoreButtonState = computed({
      get() {
        return store.state?.guide.show_more_button_is_visible;
      }
    })

    let companies = computed({
      get() {
        return store.state?.guide.companies;
      },
    });

    let all_companies = computed({
      get() {
        return store.state?.guide.all_companies;
      },
    });

    let isAjaxActive = computed({
      get() {
        return store.state.guide.ajax_state
      }
    })

    let goToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    return {
      showMoreCompanies,
      companies,
      all_companies,
      goToTop,
      showMoreButtonState,
      isAjaxActive
    };
  },
};
</script>
<style scoped>
.show-more-companies-btn {
  margin: 0px auto 20px auto;
  display: inline-block;
}

.show-more-companies-btn:hover {
  cursor: pointer;
  opacity: 0.9;
}

.show-more-info {
  display: block;
  margin: 10px auto 10px auto;
  font-size: 13px;
  text-align: center;
  color: #999999;

}
.show-more-go-to-top {
  position: fixed;
  right: 1%;
  font-size: 1.2em;
  bottom: 20px;
  display: block;
  width: 40px;
  height: 32px;
  padding-top: 8px;
  background: #65af77;
  color: white;
  text-align: center;
}

.show-more-go-to-top:hover {
  opacity: 0.7;
  cursor: pointer;
}

.empty-card {
  margin: 17.5% auto;
  padding: 80px 2%;
  font-weight: bold;
  text-align: center;
  font-weight: 1.35em;
}
</style>
