const API_HOST = process.env.VUE_APP_API_ENDPOINT_GUIDE

async function getBanners() {

    const response = await fetch(
        `${API_HOST}/api/banners`,
        {
            method: 'GET',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            }            
        }
    )

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
      }

    return await response.json()    
}

async function setImpression(banner_id) {

    let csfr_token = document.getElementsByName("csrfmiddlewaretoken")[0].value
    const response = await fetch(
        `${API_HOST}/api/banner/${banner_id}/view`,
        {
            method: 'POST',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                'X-CSRFToken': csfr_token
            }

        }
    )

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
      }

    return await response.json()    
}


export {
    getBanners,
    setImpression
}