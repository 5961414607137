<template>
    <div>
        <span class="show-on-mobile"><slot></slot></span>

        <img
        v-for="img in item.quality"
        :key="img.id"
        :src="img.image"
        alt=""
        title="img.type"
        class="quality"
        />
    </div>
</template>

<script lang="js">
export default {
  props: ["item"],
  name: "CardQualityStamp",
}
</script>
