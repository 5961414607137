<template>
  <div class="card" :class="{ isPremium: isPremium }">
    <div>
      <CardLogo :item="item"></CardLogo>
      <CardPremium v-if="item.premium"></CardPremium>
      <CardTitle :item="item">
        <slot></slot>
      </CardTitle>
      <CardDescription :item="item" v-if="item.premium"></CardDescription>
      <CardProfessionArea :item="item" v-if="item.professional_field.length > 0">Yrkesområden</CardProfessionArea>
    </div>
    <div>
      <CardQualityStamp :item="item" v-if="item.quality.length > 0">Kompetensföretagens kvalitetsstämpel
      </CardQualityStamp>
      <!-- <CardCreditworthiness :item="item" v-if="item.credit != 0">Kreditvärdighet</CardCreditworthiness> -->
      <CardButton :item="item">Visa profil</CardButton>
    </div>

  </div>
</template>

<script lang="js">
import { ref } from "vue";

import CardLogo from '@/components/CardComponenets/CardLogo.vue'
import CardTitle from '@/components/CardComponenets/CardTitle.vue'
import CardPremium from '@/components/CardComponenets/CardPremium.vue'
import CardDescription from '@/components/CardComponenets/CardDescription.vue';
import CardProfessionArea from './CardComponenets/CardProfessionArea.vue';
import CardQualityStamp from './CardComponenets/CardQualityStamp.vue';
// import CardCreditworthiness from './CardComponenets/CardCreditworthiness.vue';
import CardButton from './CardComponenets/CardButton.vue';

export default {
  name: "CompanyCard",
  props: {
    item: {}
  },
  setup(props) {
    let isPremium = ref(false)

    if (props.item.premium === true) {
      isPremium = true
    }

    return {
      isPremium
    }
  },
  components: {
    CardTitle,
    CardPremium,
    CardLogo,
    CardDescription,
    CardProfessionArea,
    CardQualityStamp,
    // CardCreditworthiness,
    CardButton
  }
};
</script> 