<template>
    <div class="banner-box" style="align-items: center; justify-content: center; ">
        <div @click="redirectBanner(item.id)" >
            <img :src=item.image class="img-banner img-desktop" />
            <img :src=item.mobile_image class="img-banner img-mobile" />
        </div>
    </div>
</template>

<script lang="js">
// import { useElementVisibility } from '@vueuse/core'
import { useStore } from "vuex";

export default {
    name: "TopBanner",
    props: {
        item: {}
    },
    mounted() {
        let store = new useStore();
        store.dispatch("banner/SET_IMPRESSION", { banner_id: this.item.id }, {
            root: true,
        })
    },
    setup() {
        const redirectBanner = function(id) {        
            window.open( '/banner/'+id, '_blank')
        }
        return {
            redirectBanner
        }
    }
}
</script>
<style>
.img-banner {
    width: 98%;
    max-width: 98%;
     margin-left: 1%;
}

@media screen and (max-width: 480px) {
    .img-mobile {
        display: inline-block !important;
        max-width: 94%;
        margin: 0 3%;
    }
    .img-desktop {
        display: none !important;
    }
}

@media screen and (min-width: 481px) {
    .img-mobile {
        display: none !important;
        max-width: 94%;
        margin: 0 3%;
    }
    .img-desktop {
        display: inline-block !important;
    }
}

</style>