import Vuex from 'vuex'
import guide from './store/guide.js'
import banner from './store/banner.js'

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        guide,
        banner
    },
    strict: debug,
  })
  
  