<template>
    <h2>
      <a :href="createPath(item)" :class="isPremiumCompany(item)"> 
        <slot></slot>
      </a>
    </h2>
</template>

<script lang="js">
export default {
  name: "CardTitle",
  props: ["item"],
  setup() {
    const createPath = (item) => {
      const DOMAIN = process.env.VUE_APP_API_ENDPOINT_GUIDE;
      if (item.slug) {
        return `${DOMAIN}/foretag/${item.slug}`;
      } else {
        return "";
      }
    };

    const isPremiumCompany = (item) => {
      if (item.premium) {
        return 'link_to_company premium_company';
      } else {
        return 'link_to_company not-premium-company';
      }
    };

    return {
      createPath,
      isPremiumCompany
    }
  }
}
</script>