<template>
 
  <div class="app-root">
    <!-- <div id="app-sidebar-nav" @onclick="show">
      <a id="nav-toggle" href="#"><span></span></a>
    </div> -->
    <div class="sidebar">
      <SideBar></SideBar>
    </div>
    <main>
      <div>
        <IntroText></IntroText>
        <TopBanner v-if="banner_top.id" :item="banner_top" ></TopBanner>
        <div class="cards" id="cards" ref="cards">
          <div :class="isAjaxActive ? 'lds-facebook ajax' : 'lds-facebook no-ajax'">
            <div></div>
            <div></div>
            <div></div>
          </div>
          <InCardBanner v-if="in_card_banner.id && companies.length > 0" :item="in_card_banner"></InCardBanner>
          <CompanyCard v-for="item in companies" :key="item.id" :item="item">{{
            item.company_name
          }}</CompanyCard>
        </div>
        <!-- <BottomBanner v-if="banner_bottom.id && companies.length > 0" :item="banner_bottom"></BottomBanner> -->
        <ShowMoreButton></ShowMoreButton>
      </div>
    </main>
  </div>
</template>

<script>
import SideBar from "@/components/SideBar.vue";
import IntroText from "@/components/IntroText.vue";
import CompanyCard from "@/components/CompanyCard.vue";
import ShowMoreButton from "@/components/ShowMoreButton.vue"

import InCardBanner from "@/components/Banners/InCardBanner.vue"
import TopBanner from "@/components/Banners/TopBanner.vue"
// import BottomBanner from "@/components/Banners/BottomBanner.vue"

import { computed, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "IndexPage",
  components: {
    SideBar,
    IntroText,
    CompanyCard,
    ShowMoreButton,
    InCardBanner,
    TopBanner,
  },
  mounted() {
    let store = new useStore();
    store.dispatch("banner/GET_BANNERS", "", {
      root: true,
    })
    // store.dispatch("guide/GET_COMPANIES", "", {
    //   root: true,
    // });

  },
  setup() {
    const store = new useStore();
    let nav = ref();

    let cards = ref(null)

    let companies = computed({
      get() {
        return store.state.guide.companies;
      },
    });

    let in_card_banner = computed({
      get() {
        return store.state.banner.banner_card 
      }
    })

    let banner_top = computed({
      get() {
        return store.state.banner.banner_top 
      }
    })

    let banner_bottom = computed({
      get() {
        return store.state.banner.banner_bottom 
      }
    })

    let isAjaxActive = computed({
      get() {
        return store.state.guide.ajax_state
      }
    })


    return {
      companies,
      isAjaxActive,
      nav,
      cards,
      in_card_banner,
      banner_top,
      banner_bottom
    };
  },
};
</script>
