<template>
    <div class="premium">
      <div>Premium</div>
    </div>
</template>

<script lang="js">
export default {
  name: "CardPremium",
}
</script>