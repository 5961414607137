<template>
  <div class="paragrpah" v-if="item.premium" v-html="truncateTxt(item, 1)"></div>
</template>

<script lang="js">
export default {
  props: ["item"],
  name: "CardDescription",
  setup() {

    const truncateTxt = (item, number_of_words) => {
      //console.log(item)
      const rt = item.description
        .split(".")
        .slice(0, number_of_words)
        .join(".");
      return `${rt}.`;
    };

    return {
      truncateTxt
    }
  }
}
</script>