<template>
  <div class="app-sidebar">
    <div id="app-sidebar-content">
    <h3>Hitta ett rekryterings- eller bemanningsföretag som passsar dig:</h3>

    <a href="/artikel/vanliga_fragor_om_rekrytering" class="anchor-faq">Vanliga frågor om rekrytering »</a>

    <SelectProfession>Välj yrkesområden</SelectProfession>  
    <SelectCompanyType>Typ av företag</SelectCompanyType>
    <SelectQuality>Kompetensföretagens kvalitetsstämpel</SelectQuality>
    <!-- <SelectCredit>Kreditvärdighet</SelectCredit>    -->  
      <div style="display: flex; justify-content: space-between;">
        <SearchButton>Sök</SearchButton>
        <ClearFiltersButton>Rensa</ClearFiltersButton>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import SelectProfession from "@/components/SidebarComponents/SelectProfession.vue"
// import SelectCredit from "@/components/SidebarComponents/SelectCredit.vue"
import SelectQuality from "@/components/SidebarComponents/SelectQuality.vue"
import SelectCompanyType from "@/components/SidebarComponents/SelectCompanyType.vue"
import ClearFiltersButton from "@/components/SidebarComponents/ClearFiltersButton.vue"
import SearchButton from "@/components/SidebarComponents/SearchButton.vue"

export default {
  name: "SideBar",
  components: {
    SelectProfession,    
    SelectQuality,
    SelectCompanyType,
    ClearFiltersButton,
    SearchButton
  } 
};
</script>