import {
    getBanners,
    setImpression
}

    from '../api/api_banner'

const state = () => ({
    banner_top: {},
    banner_card: {},
    banner_bottom: {},
    banners: false,
    set_impression : false
})

const getters = {
    banner_card: state => {
        return state.banner_card
    },
    banner_top: state => {
        return state.banner_top
    },
    banner_bottom: state => {
        return state.banner_bottom
    }
}

const actions = { 
    GET_BANNERS: ({ commit, dispatch }) => {
        getBanners().then((data) => {
            commit('set_banners', data)
            dispatch('guide/GET_COMPANIES','',{root:true})
        }).catch((error) => {
            console.log(error)
            commit('set_banners', {})

        })
    },
    SET_IMPRESSION: ({commit}, banner_id) => {
        setImpression(banner_id.banner_id)
        commit('set_impression', true)
    }
}

const mutations = {
    set_impression(value) {
        state.set_impression = value
    },
    set_banners(state, value) {
         for ( let el of value ) {
            if (  el.position == 'CON' ) {
                state.banner_card = el
                console.info("set query limit")
                this.state.guide.query_limit = 11
            }
            else if ( el.position == 'TOP' ) {
                state.banner_top = el
            } 
            else if ( el.position == 'BOT') {
                state.banner_bottom = el
            }
         }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}