const API_HOST = process.env.VUE_APP_API_ENDPOINT_GUIDE

async function getProfessions() {
    const response = await fetch(
        `${API_HOST}/api/guide/professions`,
        {
            method: 'GET',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            }            
        }
    )

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
      }

    return await response.json()    
}


async function getRecruitmentTypes() {
    const response = await fetch(
        `${API_HOST}/api/guide/recruitment-types`,
        {
            method: 'GET',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            }            
        }
    )

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
      }

    return await response.json()    
}

async function getSelectedCompanies(filters) {
    const response = await fetch(
        `${API_HOST}/api/guide/companies?${filters}`,
        {
            method: 'GET',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            }
        }
    )

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
      }

    return await response.json()    
} 

export {
    getProfessions,
    getSelectedCompanies,
    getRecruitmentTypes
}