<template>
  <div>
    <h4>
      <slot></slot>
    </h4>

    <div style="display: flex; gap: 40%">
      <div>
        <label class="form-control select-profession" v-for="item in listOfProfessions[0]" :key="item.id">
          <input name="type_of_job" type="checkbox" :value="item.id" v-model="checkedProffesions"
             />
          {{ item.name }}
        </label>
      </div>

      <div>
        <label class="form-control select-profession" v-for="item in listOfProfessions[1]" :key="item.id">
          <input name="type_of_job" type="checkbox" :value="item.id" v-model="checkedProffesions"
             />
          {{ item.name }}
        </label>
      </div>
    </div>
    <!-- grid inline-->
  </div>
</template>

<script lang="js">
import { computed } from "vue";
import { useStore } from "vuex";
//import { useGtm } from "@gtm-support/vue-gtm";
// import { event } from "vue-gtag";

export default {
  name: "SelectProfession",
  mounted() {
    var store = new useStore();
    store.dispatch("guide/GET_PROFFESIONS", "", { root: true });
  },
  setup() {
    var store = new useStore();

    //const checkedProffesions = ref([]);

    const checkedProffesions = computed({
      get() {
        return store.state.guide.filter_professions
      },
      set() {
        let arr = []
        let el = document.getElementsByName('type_of_job')
        for (const item of el) {
          if (item.checked) {
            arr.push(parseInt(item.value))
          }
        }

        store.dispatch("guide/UPDATE_FILTER_PROFFESION", arr, {
          root: true,
        });

      }
    })

    const listOfProfessions = computed({
      get() {
        let length = Math.ceil(store.state.guide.professions.length / 2);
        const professional_field_left_column =
          store.state.guide.professions.slice(0, length);
        const professional_field_right_column =
          store.state.guide.professions.slice(length);

        return [
          professional_field_left_column,
          professional_field_right_column,
        ];
      },
    });

    // const updateCheckedProfessions = (item) => {
    //   triggerEventToGA(item);

    //   store.dispatch(
    //     "guide/UPDATE_FILTER_PROFFESION",
    //     checkedProffesions.value,
    //     {
    //       root: true,
    //     }
    //   );

    // };

    // const triggerEventToGA = (item) => {
      
    //   if (checkedProffesions.value.includes(item.id)) {
  
    //     event("search", {
    //       event_category: "Sök",
    //       event_label: "Yrkeområde",
    //       value: item.name,
    //     });
    //   }
    // };

    return {
      listOfProfessions,
      checkedProffesions,
      //updateCheckedProfessions,
    };
  },
};
</script>
