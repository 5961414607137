<template>
  <div @click="searchCompanies()" class="btn">
    <slot></slot>
  </div>
</template>

<script>
import { useStore } from "vuex";
//import { ref } from 'vue'

export default {
  setup() {
    const store = new useStore();

    const searchCompanies = () => {

      // function getAbsoluteOffsetTop(el) {
      //   let offset = 0;
      //   let currentElement = el;

      //   while (currentElement !== null) {
      //     offset += currentElement.offsetTop;
      //     offset -= currentElement.scrollTop;
      //     currentElement = currentElement.offsetParent;
      //   }

      //   return offset;
      // }

      store.dispatch("guide/CLEAR_RESULTS_WHEN_SEARCH")

      const el = document.getElementById('cards')
      el.scrollIntoView()  

      // const el = document.getElementById('cards')
      // let position = getAbsoluteOffsetTop(el)
      //  el.scrollIntoView({ block: "nearest", inline: "end", behavior: "smooth" })
      // let sp = position * 2
      // console.log("element position is" ,sp)

      // window.scrollTo(
      //   {
      //     top: 0,
      //     left: 0,
      //   }
      // );

    };

    return {
      searchCompanies,
    };
  },
};
</script>
<style scoped>
.btn {
  background-color: #65af77;
  color: white;
  margin-top: 20px;
}
</style>