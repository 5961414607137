<template>
  <div>
    <span class="show-on-mobile">
      <slot></slot>
    </span>
    <ul>
      <li v-for="element in professionalAreas" :key="element">
        {{ element.name }}
      </li>
      <li v-if="countElementsInProffesionalAreas" class="noListButton">+ {{ countElementsInProffesionalAreas }} till</li>
    </ul>
  </div>
</template>

<script lang="js">
import { computed, ref } from "vue";

export default {
  props: ["item"],
  name: "CardProfessionArea",
  setup(props) {

    let numberOfElementsToShow = ref(3);

    const countElementsInProffesionalAreas = computed(() => {
      if (props.item.professional_field.length > numberOfElementsToShow.value) {
        return props.item.professional_field.length - numberOfElementsToShow.value;
      } else {
        return 0
      }
    })

    const professionalAreas = computed(() => {
      let pr = props.item.professional_field;
      return pr.slice(0, numberOfElementsToShow.value)
    })

    return {
      professionalAreas,
      countElementsInProffesionalAreas
    }
  }
}
</script>