<template>
    <div class="logo-container" :class="{ noLogo: noLogo }">
        <a :href="createPath(item)" >
            <img :src="getLogo(item)" :alt="getAltAttribute(item)" class="company-logo" />
        </a>
    </div>
</template>

<script lang="js">
import { ref } from "vue";

export default {
    name: "CardLogo",
    props: ["item"],
    setup(props) {
        let noLogo = ref(true)

        if (props.item.logo[0]) {
            noLogo = false
        }

        const getLogo = (item) => {
            if (item.logo[0]) {
                return item.logo[0].company_logo;
            } else {
                return "";
            }
        };

        const getAltAttribute = (item) => {
            if (item.logo[0]) {
                return `Logo - ${item.company_name}`
            } else {
                return ""
            }
        }

        const createPath = (item) => {
            const DOMAIN = process.env.VUE_APP_API_ENDPOINT_GUIDE;
            if (item.slug) {
                return `${DOMAIN}/foretag/${item.slug}`;
            } else {
                return "";
            }
        };

        return {
            noLogo,
            getLogo,
            getAltAttribute,
            createPath
        }
    }

}
</script>